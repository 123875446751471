<template>
	<div>
		<el-menu class="el-menu-vertical-demo" :default-active="leftIndex" :collapse="isCollapse">
			<p class="menu-h5" v-if="!isCollapse">
				<img src="../../static/images/logo.png" >
			</p>
			<i class="el-icon-location menu-icon" v-else></i>
			<!-- <el-menu-item index="1" @click="menuitemClick('/index')" v-if="home">
				<i class="el-icon-s-home"></i>
				<span slot="title">首页</span>
			</el-menu-item> -->
			<el-menu-item index="2" @click="menuitemClick('/statistics')" v-if="zlgs">
				<i class="el-icon-office-building"></i>
				<span slot="title">主流公司管理</span>
			</el-menu-item>
			<el-menu-item index="10" @click="menuitemClick('/mine')" v-if="ks">
				<i class="el-icon-s-data"></i>
				<span slot="title">矿山管理</span>
			</el-menu-item>
			<el-menu-item index="3" @click="menuitemClick('/administrators')" v-if="gly">
				<i class="el-icon-s-custom"></i>
				<span slot="title">管理员管理</span>
			</el-menu-item>
			<!-- <el-menu-item index="4" @click="menuitemClick('/knowledge')" v-if="dysj">
				<i class="el-icon-menu"></i>
				<span slot="title">点云数据管理</span>
			</el-menu-item> -->
			<el-menu-item index="5" @click="menuitemClick('/user')" v-if="user">
				<i class="el-icon-user"></i>
				<span slot="title">用户管理</span>
			</el-menu-item>
			<el-menu-item index="6" @click="menuitemClick('/sensor')" v-if="msd">
				<i class="el-icon-s-data"></i>
				<span slot="title">产品在线监测</span>
			</el-menu-item>
			<!-- <el-menu-item index="11" @click="menuitemClick('/mill')" v-if="mj">
				<i class="el-icon-s-data"></i>
				<span slot="title">设备管理</span>
			</el-menu-item> -->
			<!-- <el-menu-item index="7" @click="menuitemClick('/millDiameter')" v-if="mjzj">
				<i class="el-icon-menu"></i>
				<span slot="title">磨机直径管理</span>
			</el-menu-item> -->
			<el-menu-item index="8" @click="menuitemClick('/message')" v-if="ly">
				<i class="el-icon-chat-dot-square"></i>
				<span slot="title">留言管理</span>
			</el-menu-item>
			<el-menu-item index="9" @click="menuitemClick('/company')" v-if="gsgl">
				<i class="el-icon-office-building"></i>
				<span slot="title">公司管理</span>
			</el-menu-item>
			<el-menu-item index="12" @click="menuitemClick('/product')">
				<i class="el-icon-document"></i>
				<span slot="title">经典案例</span>
			</el-menu-item>
			<el-menu-item index="13" @click="menuitemClick('/literature')">
				<i class="el-icon-tickets"></i>
				<span slot="title">文献管理</span>
			</el-menu-item>
      <el-menu-item index="14" @click="menuitemClick('/standard')">
				<i class="el-icon-tickets"></i>
				<span slot="title">标准管理</span>
			</el-menu-item>
      <el-menu-item index="15" @click="menuitemClick('/patent')">
				<i class="el-icon-tickets"></i>
				<span slot="title">专利管理</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	export default {
		props: {
			leftIndex: {
				type: String,
				default: "1",
			},
			isCollapse: {
				type: Boolean,
				default: false,
			},
		},
		inject: ["reload"],
		data() {
			return {
				home: true,
				zlgs: true,
				ks: true,
				gly: true,
				dysj: true,
				msd:true,
				mj: true,
				mjzj: true,
				ly: true,
				gsgl: true,
				user: true,
				cgq:true
			};
		},
		mounted() {
			let qx = JSON.parse(localStorage.getItem("quanxian"));
			if (qx) {
				for (let i = 0; i < qx.length; i++) {
					if (qx[i].name == "首页") {
						this.home = true;
					}
					if (qx[i].name == "数据统计") {
						this.sjtj = true;
					}
					if (qx[i].name == "管理员") {
						this.gly = true;
					}
					if (qx[i].name == "科普知识") {
						this.kpzs = true;
					}
					if (qx[i].name == "商品管理") {
						this.spgl = true;
					}
					if (qx[i].name == "订单") {
						this.dd = true;
					}
					if (qx[i].name == "闲赚") {
						this.xz = true;
					}
					if (qx[i].name == "设置") {
						this.sz = true;
					}
					if (qx[i].name == "用户") {
						this.user = true;
					}
				}
			}
			// console.log(qx);
		},
		methods: {
			menuitemClick(pushUrl) {
				// console.log(this.$route.path == pushUrl);
				if (this.$route.path == pushUrl) {
					this.reload();
				} else if (pushUrl) {
					this.$router.push(pushUrl);
				} else {
					this.$notify.error({
						title: "提示",
						message: "暂无页面",
					});
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss">
	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;

		i {
			color: #3291f8;
		}
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
		img{
			width: 100%;
		}
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}

	.iconyonghuzu {
		font-size: 18px;
		margin: 0 5px;
	}
</style>
