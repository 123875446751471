<template>
	<div class="top-header">
		<div class="left-icon">
			<i class="el-icon-s-fold" v-if="!isCollapse" @click="openMenu"></i>
			<i class="el-icon-s-unfold" v-else @click="openMenu"></i>
			<i class="el-icon-refresh-right" @click="Refresh"></i>
		</div>
		<div class="right-user">
			<span>欢迎您，</span>
			<span class="username" @click="undatePassword">{{ username }}</span>
			<div class="colse-btn" @click="signOut">
				<span>退出</span>
				<i class="el-icon-switch-button"></i>
			</div>
		</div>
		<el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="600px" center>
			<div class="from-item">
				<span>输入旧密码<span class="txt-red">*</span>：</span>
				<div class="from-item-input">
					<input type="password" v-model="fromList.usedPassword" placeholder="请输入密码" />
				</div>
			</div>
			<div class="from-item">
				<span>输入新密码<span class="txt-red">*</span>：</span>
				<div class="from-item-input">
					<input type="password" v-model="fromList.password" placeholder="请输入密码" />
				</div>
			</div>
			<div class="from-item">
				<span>确认新密码<span class="txt-red">*</span>：</span>
				<div class="from-item-input">
					<input type="password" v-model="fromList.andpass" placeholder="请输入密码" />
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="updatePass">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import Cookies from 'js-cookie';
	export default {
		inject: ["reload"],
		data() {
			return {
				centerDialogVisible: false,
				fromList: {
					usedPassword: '',
					password: "",
					andpass: "",
				},
				username: JSON.parse(Cookies.get("remember_userinfo")).username,
			};
		},
		props: {
			isCollapse: {
				type: Boolean,
				default: false,
			},
		},
		created() {},
		methods: {
			Refresh() {
				this.reload();
			},
			openMenu() {
				this.$emit("openMenu", "");
			},
			signOut() {
				this.$notify({
					title: "提示",
					message: "操作成功！",
					type: "success",
				});
				Cookies.remove('rememberToken');
				Cookies.remove('remember_userinfo');
				// localStorage.removeItem("rememberToken");
				// localStorage.removeItem("userinfo");
				this.$router.push("/");
			},
			updatePass() {
				this.$post("user/self_reset_pwd", {
					new_password: this.fromList.password,
					password: this.fromList.usedPassword,
				}).then((res) => {
					if (res.data.status == 200) {
						this.$notify({
							title: "提示",
							message: "修改成功,请重新登录！",
							type: "success",
						});
						Cookies.remove('rememberToken');
						Cookies.remove('remember_userinfo');
						this.$router.push("/");
					} else {
						this.$notify.error({
							title: "提示",
							message: res.data.message,
						});
					}
				});
			},
			undatePassword() {
				this.centerDialogVisible = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.top-header {
		width: 100%;
		display: flex;
		box-sizing: border-box;
		justify-content: space-between;

		.left-icon {
			i {
				cursor: pointer;
				color: #3291f8;
				font-size: 30px;
			}

			.el-icon-refresh-right {
				margin-left: 30px;
			}
		}

		.right-user {
			font-size: 14px;

			.username {
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #66b1ff;
				}
			}

			span {
				vertical-align: middle;
				margin-right: 20px;
			}

			.colse-btn {
				cursor: pointer;
				vertical-align: middle;
				border: 1px solid #999;
				display: inline-block;
				padding: 5px 20px;
				border-radius: 50px;

				span {
					margin-right: 10px;
				}

				i {
					vertical-align: middle;
				}
			}
		}
	}

	.from-item {
		margin-top: 50px;

		.from-item-select {
			display: inline-block;
			margin-left: 30px;
		}

		.from-item-input {
			display: inline-block;
			width: 200px;
			border-bottom: 1px solid #bbb5b5;
			margin-left: 30px;

			input {
				width: 190px;
				border: 0px;
				outline: none;
			}
		}
	}
</style>
