<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'8'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>设置</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>收货地址</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚海报</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>闲赚比例</span>
        </div>
        <div class="left-list-item" @click="leftClick('3')">
          <span>驳回原因</span>
        </div>
        <div class="left-list-item" @click="leftClick('4')">
          <span>客服</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>收货地址</span>
        </div>
        <div class="mt-20">
          <div class="address-from">
            <div class="from-item">
              <span class="from-label"
                >收件人姓名<span class="txt-red">*</span>：</span
              >
              <div class="from-item-input">
                <input type="text" v-model="fromList.link_name" />
              </div>
            </div>
            <div class="from-item">
              <span class="from-label"
                >收件人手机号<span class="txt-red">*</span>：</span
              >
              <div class="from-item-input">
                <input type="text" v-model="fromList.link_number" />
              </div>
            </div>
            <div class="from-item">
              <span class="from-label"
                >收货地区<span class="txt-red">*</span>：</span
              >
              <div class="from-item-select">
                <el-select
                  v-model="fromList.province_name"
                  placeholder="请选择省"
                  @change="bindPickerChange"
                >
                  <el-option
                    v-for="item in province"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="fromList.city_name"
                  placeholder="请选择市"
                  @change="bindPickercityChange"
                >
                  <el-option
                    v-for="item in city"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="fromList.region_name"
                  placeholder="请选择区"
                  @change="bindPickerareaChange"
                >
                  <el-option
                    v-for="item in area"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="from-item">
              <span class="from-label"
                >收件人详细地址<span class="txt-red">*</span>：</span
              >
              <div class="from-item-input">
                <input type="text" v-model="fromList.address_detail" />
              </div>
            </div>
            <div class="from-item">
              <el-button type="primary" @click="addressSubmit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      province: [],
      city: [],
      area: [],
      fromList: {
        link_name: "",
        link_number: "",
        address_deatil: "",
        province_name: "", //省名
        city_name: "", //城市名
        region_name: "", // 区名
      },
    };
  },
  created() {
    this.getAddressinfo();
    this.getProvince();
  },
  methods: {
    addressSubmit() {
      this.$post("upd/address_setting", {
        address_value: this.fromList,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "修改成功！",
            type: "success",
          });
          this.getAddressinfo();
        }else{
           this.$notify.error({
          title: '提示',
          message: res.data.message
        });
        }
        console.log(res);
      });
    },
    getAddressinfo() {
      this.$get("get/address_setting", {}).then((res) => {
        this.fromList.link_name = res.data.data.link_name;
        this.fromList.link_number = res.data.data.link_number;
        this.fromList.address_detail = res.data.data.address_detail;
        // console.log(this.fromList);
      });
    },
    getProvince() {
      this.$get("get/province", {}).then((res) => {
        console.log(res);
        this.province = res.data.data;
        this.fromList.province_name = res.data.data[0].name;
        this.getCity(res.data.data[0].id);
      });
    },
    getCity(ID) {
      this.$get("get/city", {
        province_id: ID,
      }).then((res) => {
        console.log(res.data);
        this.city = res.data.data;
        this.fromList.city_name = res.data.data[0].name;
        this.getRegion(res.data.data[0].id);
      });
    },
    getRegion(id) {
      this.$get("get/region", {
        city_id: id,
      }).then((res) => {
        console.log(res);
        this.area = res.data.data;
        this.fromList.region_name = res.data.data[0].name;
      });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/setup") {
        this.$router.push("/setup");
      } else if (index == 1 && this.$route.path != "/poster") {
        this.$router.push("/poster");
      } else if (index == 2 && this.$route.path != "/proportion") {
        this.$router.push("/proportion");
      } else if (index == 3 && this.$route.path != "/reject") {
        this.$router.push("/reject");
      } else if (index == 4 && this.$route.path != "/customer") {
        this.$router.push("/customer");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    bindPickerChange: function (event) {
      console.log(event);
      let c1 = this.province.findIndex((ace) => ace.name == event);
      // console.log(this.province[c1].id);
      this.$get("get/city", {
        province_id: this.province[c1].id,
      }).then((res) => {
        // console.log(res);
        this.city = res.data.data;
        this.fromList.city_name = res.data.data[0].name;
      });
      this.area = [];
      this.fromList.region_name = "";
    },
    bindPickercityChange(event) {
      let c1 = this.city.findIndex((ace) => ace.name == event);
      this.$get("get/region", {
        city_id: this.city[c1].id,
      }).then((res) => {
        // console.log(res);
        this.area = res.data.data;
        this.fromList.region_name = res.data.data[0].name;
      });
    },
    bindPickerareaChange(event) {
      this.fromList.region_name = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .address-from {
    margin-top: 30px;
    padding-left: 150px;
    .from-item {
      margin-top: 50px;
      .from-label {
        display: inline-block;
        width: 150px;
      }
      .from-item-select {
        display: inline-block;
        margin-left: 30px;
        .el-select {
          margin-right: 20px;
          width: 200px;
        }
      }
      .from-item-input {
        display: inline-block;
        width: 250px;
        border-bottom: 1px solid #bbb5b5;
        margin-left: 30px;
        input {
          width: 240px;
          border: 0px;
          outline: none;
        }
      }
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
